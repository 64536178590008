<template>
  <div>
    <h2 class="pt5">Coin&Spin daily rewards</h2>
    <h4>{{ $route.query.name }} - {{ $route.query.date }}</h4>
    <p style="font-size: 16px;">Collect Now to Get Rewards.🎁🎁⬇️⬇️</p>
    <br/>
    <a :href="this.href" onclick="this.closeMe">
      <img :src="collectIcon" alt="collect" class="mb-4" style="width: 208px; height: 85px;"/>
    </a>
    <br/><br/>
    <span style="color: red; font-size: 16px;">Tip: all links have a time limit, if the collection is not successful, it may have expired or have been collected. It will take some time to jump to CM app. Please be patient.</span>

    <p>_______________________________________</p>

    <div class="row mx-1 my-3">
      <div class="col-12 text-center border border-dark">
        <span style="font-size: 16px;">{{ appDesc }}</span><br/><br/>
        <a :href="this.appStoreUrl"><img :src="appStoreIcon" alt="appStore" style="width: 184px; height: 62px;"/></a>
        <a :href="this.gpUrl"><img :src="gpIcon" alt="get" class="mb-4" style="width: 184px; height: 62px;"/></a>

      </div>

      <div class="row mx-1 my-3">
        <div class="col-12 text-center bg-dark">
          <h6 class="m-0 p-1"><a :href="this.privacy" target="_blank">Privacy Policy</a></h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollectDetail",
  props: {
    platform: String
  },
  data() {
    return {
      href: '',
      privacy: '',
      appDesc: '🔹 Spin Master for Daily Spin rewards for Coin Master. Download now on App Store or Google Play.',
      gpUrl: 'https://play.google.com/store/apps/details?id=com.free.coins.spins.link.master.rewards',
      appStoreUrl: 'https://apps.apple.com/app/id6443577675',
      collectIcon: require('../assets/button.png'),
      appStoreIcon: require('../assets/app_store.png'),
      gpIcon: require('../assets/app_google.png')
    }
  },
  mounted() {
    this.href = this.$route.query.url

    if (this.href != null) {
      let pos = this.href.lastIndexOf('?c=');
      if (pos > 0) {
        let token = this.href.substring(pos + 3, this.href.length)
        if (this.platform == null || this.platform === 'i') {
          this.href = 'coinmaster://promotions?af_deeplink=true&media_source=FB_PAGE&campaign=' + token
          this.privacy = 'https://spinmasterfree.com/privacyios.html'
        } else if (this.platform === 'a') {
          this.privacy = 'https://spinmasterfree.com/privacy.html'
        }
      }
    }
  },
  methods: {
    closeMe() {
      if (navigator.userAgent.indexOf("Firefox") !== -1 || navigator.userAgent.indexOf("Chrome") !== -1) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    }
  }
}
</script>

<style scoped>
.pt5 {
  padding-top: 20px;
}

.bg-dark {
  background-color: #96b0f3;
}

.p-1 {
  padding: 10px;
}
</style>