<template>
  <div class="home">
    <collect-detail-new :platform="this.platform"/>
  </div>
</template>

<script>
// @ is an alias to /src
import CollectDetailNew from "@/components/CollectDetailNew";

export default {
  name: 'CollectView',
  components: {
    CollectDetailNew
  },
  data() {
    return {
      platform: ''
    }
  },
  created() {
    this.platform = this.$route.params.platform;
  }
}
</script>
