<template>
  <div class="home">
    <collect-detail :platform="this.platform"/>
  </div>
</template>

<script>
// @ is an alias to /src
import CollectDetail from "@/components/CollectDetail";

export default {
  name: 'HomeView',
  components: {
    CollectDetail
  },
  data() {
    return {
      platform: ''
    }
  },
  created() {
    this.platform = this.$route.params.platform;
  }
}
</script>
