<template>
  <div class="home">
    <upgrade-detail/>
  </div>
</template>

<script>
// @ is an alias to /src
import UpgradeDetail from "@/components/UpgradeDetail.vue";

export default {
  name: 'UpgradeView',
  components: {
    UpgradeDetail,
  },
}
</script>