<template>
  <div class="home">
    <share-detail :platform="this.platform"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ShareDetail from "@/components/ShareDetail";

export default {
  name: 'ShareView',
  components: {
    ShareDetail
  },
  data() {
    return {
      platform: ''
    }
  },
  created() {
    this.platform = this.$route.params.platform;
  }
}
</script>
