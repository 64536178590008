<template>
  <div>
    <!-- 显示 升级页面 的内容 -->
    <div v-if="upgrade">
      <h1 class="pt5">App Upgrade Notice!!!</h1>
      <!--      <p style="font-size: 16px; color: red; text-align: center;">
              Due to the upgrade of CM version,<br/>
              the current version is no longer available.<br/>
              Please upgrade the latest version as soon as possible,<br/>
              To get more spin link and reward coins.
            </p>-->
      <p style="font-size: 16px; color: red; text-align: left; padding-left: 10px;">
        Hello, due to your outdated app version, we are unable to provide the best service. We kindly invite you to
        upgrade for an improved experience.
        <br>Thank you for your support.
      </p>
      <br/>
      <span style="color: red;">Goto Google Play upgrade.</span>
      <br/>
      <span>⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️</span>
      <br/>
      <a :href="this.gpUrl">
        <br/>
        <img :src="gpIcon" alt="collect" class="mb-4" style="width: 276px; height: 85px;"/>
      </a>
      <br/><br/>
      <br/><br/>
      <br/><br/>
      <hr/>
      <div class="row mx-1 my-3">
        <div class="row mx-1 my-3">
          <div class="col-12 text-center bg-dark">
            <h6 class="m-0 p-1"><a :href="this.privacy" target="_blank">Privacy Policy</a></h6>
          </div>
        </div>
      </div>
    </div>
    <!-- 显示 领取页面 的内容 -->
    <div v-else>
      <h2 class="pt5">Coin&Spin daily rewards</h2>
      <h4>{{ $route.query.name }} - {{ $route.query.date }}</h4>
      <p style="font-size: 16px;">Collect Now to Get Rewards.</p>
      <br/>
      <a :href="this.href">
        <img :src="collectIcon" alt="collect" class="mb-4" style="width: 208px; height: 85px;"/>
      </a>
      <br/><br/>
      <span style="color: red; font-size: 16px;">Tip: all links have a time limit, if the collection is not successful,
        it may have expired or have been collected. It will take some time to jump to CM app. Please be patient.</span>

      <p>_______________________________________</p>

      <div class="row mx-1 my-3">
        <div class="col-12 text-center border border-dark">
          <span style="font-size: 16px;">{{ appDesc }}</span><br/><br/>
          <a :href="this.appStoreUrl"><img :src="appStoreIcon" alt="appStore" style="width: 184px; height: 62px;"/></a>
          <a :href="this.gpUrl"><img :src="gpIcon" alt="get" class="mb-4" style="width: 184px; height: 62px;"/></a>
        </div>

        <div class="row mx-1 my-3">
          <div class="col-12 text-center bg-dark">
            <h6 class="m-0 p-1"><a :href="this.privacy" target="_blank">Privacy Policy</a></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CollectDetailNew",
  props: {
    platform: String
  },
  data() {
    return {
      href: '',
      privacy: '',
      token: '',
      appDesc: '🔹 Spin Master for Daily Spin rewards for Coin Master. Download now on App Store or Google Play.',
      gpUrl: 'https://play.google.com/store/apps/details?id=com.free.coins.spins.link.master.rewards',
      appStoreUrl: 'https://apps.apple.com/app/id6443577675',
      collectIcon: require('../assets/button.png'),
      appStoreIcon: require('../assets/app_store.png'),
      gpIcon: require('../assets/app_google.png')
    }
  },
  computed: {
    upgrade() {
      const name = this.$route.query.name;
      const upgrade = name.includes("coins") || name.includes("spins") || name === 'App Upgrade Notice' || name ===
          '50 Free Spins!';
      console.log('upgrade: ' + upgrade);
      return upgrade;
    }
  },
  mounted() {
    if (this.platform === 'i') {
      this.token = this.$route.query.token
      this.privacy = 'https://spinmasterfree.com/privacyios.html'
      this.href = 'coinmaster://promotions?af_deeplink=true&media_source=FB_PAGE&campaign=' + this.token
    } else if (this.platform === 'a') {
      this.privacy = 'https://spinmasterfree.com/privacy.html'
      this.href = this.$route.query.url
    }
  }
}
</script>

<style scoped>
.pt5 {
  padding-top: 20px;
}

.bg-dark {
  background-color: #96b0f3;
}

.p-1 {
  padding: 10px;
}
</style>