import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ShareView from "../views/ShareView";
import CollectView from "../views/CollectView";
import UpgradeView from "@/views/UpgradeView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/collect/:platform',
    name: 'collect',
    component: CollectView
  },
  {
    path: '/share/:platform',
    name: 'share',
    component: ShareView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/up',
    name: 'upgrade',
    component: UpgradeView
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router
