<template>
  <div>
    <h2 class="pt5">Add Friend</h2>
    <h4>{{ $route.query.title }}</h4>
    <h5>{{ $route.query.datetime }} <img :src="likesIcon" style="margin-left: 80px; height: 20px;width: 20px;"
                                         alt="likes"/> {{ $route.query.likes }}</h5>
    <br/>
    <a :href="this.href">
      <img :src="addFriendIcon" alt="addFriend" class="mb-4" style="width: 180px; height: 90px; border-radius: 10px;"/>
    </a>

    <p>_______________________________________</p>

    <div class="row mx-1 my-3">

      <div class="col-12 text-center bg-dark border border-dark">
        <span class="m-0 p-1" style="font-size: 16px;"><a :href="this.appUrl" class="text-white">{{ this.appDesc }}</a></span>
      </div>

      <div class="row mx-1 my-3">
        <div class="col-12 text-center bg-dark">
          <h6 class="m-0 p-1"><a :href="this.privacy" target="_blank" class="text-white">Privacy Policy</a></h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "CollectDetail",
  props: {
    platform: String
  },
  methods: {
    goUrl(url) {
      window.open(url)
    }
  },
  data() {
    return {
      href: '',
      privacy: '',
      appUrl: '',
      appDesc: '',
      addFriendIcon: require('../assets/ic_add_friend.png'),
      likesIcon: require('../assets/icon_zan_sel.png')
    }
  },
  mounted() {
    this.href = this.$route.query.url
    if (this.platform === 'i') {
      this.privacy = 'https://spinmasterfree.com/privacyios.html'
      this.appDesc = '🔹 Spin Master for Daily Spin rewards for Coin Master. Download now on Appstore for iPhone Users'
      this.appUrl = 'https://apps.apple.com/app/id6443577675'
    } else if (this.platform === 'a') {
      this.privacy = 'https://spinmasterfree.com/privacy.html'
      this.appDesc = '🔹 Spin Master for Daily Spin rewards for Coin Master. Download now on Google Play for Android Users'
      this.appUrl = 'https://play.google.com/store/apps/details?id=com.free.coins.spins.link.master.rewards'
    }
  }
}
</script>

<style scoped>
.pt5 {
  padding-top: 20px;
}

.bg-dark {
  background-color: #96b0f3;
}

.p-1 {
  padding: 10px;
}
</style>