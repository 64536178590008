<template>
  <div>
    <h1 class="pt5">App Upgrade Notice!!!</h1>
    <p style="font-size: 16px; color: red; text-align: center;">
      Due to the upgrade of CM version,<br/>
      the current version is no longer available.<br/>
      Please upgrade the latest version as soon as possible,<br/>
      To get more spin link and reward coins.

    </p>
    <br/>
    <span style="color: red;">Goto Google Play upgrade.</span>
    <br/>
    <span>⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️⬇️</span>
    <br/>
    <a :href="this.gpUrl">
      <br/>
      <img :src="gpIcon" alt="collect" class="mb-4" style="width: 276px; height: 85px;"/>
    </a>
    <br/><br/>
    <br/><br/>
    <br/><br/>
    <hr/>
    <div class="row mx-1 my-3">
<!--      <div class="col-12 text-center border border-dark">-->
<!--        <span style="font-size: 16px;">{{ appDesc }}</span><br/><br/>-->
<!--        <a :href="this.appStoreUrl"><img :src="appStoreIcon" alt="appStore" style="width: 184px; height: 62px;"/></a>-->
<!--        <a :href="this.gpUrl"><img :src="gpIcon" alt="get" class="mb-4" style="width: 184px; height: 62px;"/></a>-->
<!--      </div>-->

      <div class="row mx-1 my-3">
        <div class="col-12 text-center bg-dark">
          <h6 class="m-0 p-1"><a :href="this.privacy" target="_blank">Privacy Policy</a></h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpgradeDetail",
  props: {
    platform: String
  },
  data() {
    return {
      href: '',
      privacy: '',
      appDesc: '🔹 Spin Master for Daily Spin rewards for Coin Master. Download now on App Store or Google Play.',
      gpUrl: 'https://play.google.com/store/apps/details?id=com.free.coins.spins.link.master.rewards',
      appStoreUrl: 'https://apps.apple.com/app/id6443577675',
      collectIcon: require('../assets/button.png'),
      appStoreIcon: require('../assets/app_store.png'),
      gpIcon: require('../assets/app_google.png')
    }
  },
}
</script>

<style scoped>
.pt5 {
  padding-top: 20px;
}

.bg-dark {
  background-color: #96b0f3;
}

.p-1 {
  padding: 10px;
}
</style>